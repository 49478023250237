<template>
  <div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 opacity-0" ref="tnav">
      <nav
        class="relative flex items-center justify-between sm:h-10 md:justify-center"
        aria-label="Global"
      >
        <div
          class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0"
        >
          <div class="flex items-center justify-between w-full md:w-auto">
            <router-link to="/" class="flex items-center">
              <span class="sr-only">Rayfish</span>
              <img class="h-8 w-auto -mt-1.5" src="@/assets/logo.png" alt="" />
              <span
                class="ml-4 text-zinc-200 font-logo text-base tracking-normal"
                >Rayfish</span
              >
            </router-link>
            <div class="-mr-2 flex items-center hidden">
              <button
                type="button"
                class="-mt-2 rounded-md p-2 inline-flex items-center justify-center text-zinc-300 hover:text-zinc-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                <!-- Heroicon name: outline/menu -->
                <svg
                  class="h-8 w-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <!--<div class="hidden md:flex md:space-x-10">
          <a
            href="#"
            class="font-medium font-semibold text-zinc-200 hover:underline"
            >Download</a
          >

          <a
            href="#"
            class="font-medium font-semibold text-zinc-200 hover:underline"
            >Pricing</a
          >

          <a
            href="#"
            class="font-medium font-semibold text-zinc-200 hover:underline"
            >Careers</a
          >
        </div>-->
        <div
          class="md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0"
        >
          <span class="inline-flex rounded-full">
            <a
              href="https://www.github.com/rayfish"
              target="_blank"
              class="inline-flex transform duration-500 rounded-full items-center px-4 py-2 border border-transparent text-sm font-semibold font-semibold rounded-md text-zinc-900 bg-zinc-100 hover:bg-zinc-400"
            >
              Careers 🚀
            </a>
          </span>
        </div>
      </nav>
    </div>

    <!--
      Mobile menu, show/hide based on menu open state.

      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
    <div
      class="absolute hidden z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
    >
      <div
        class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
      >
        <div class="px-5 pt-4 flex items-center justify-between">
          <div>
            <img
              class="h-8 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt=""
            />
          </div>
          <div class="-mr-2">
            <button
              type="button"
              class="inline-flex items-center justify-center text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500"
            >
              <span class="sr-only">Close menu</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="px-2 pt-2 pb-3">
          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >Product</a
          >

          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >Features</a
          >

          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >Marketplace</a
          >

          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >Company</a
          >
        </div>
        <a
          href="#"
          class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
        >
          Login
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  mounted() {
    gsap.to(this.$refs.tnav, {
      opacity: 1,
      duration: 0.5,
      delay: 3.8,
    });
  },
};
</script>
