<template>
  <main class="h-full mx-auto px-4 sm:px-6">
    <div
      class="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8"
    >
      <div class="grid grid-cols-8 lg:grid-cols-12 pb-16 lg:pb-0">
        <div class="col-span-8 flex items-center justify-center mt-12 lg:mt-0">
          <div class="w-96 space-y-12">
            <div>
              <img
                class="mx-auto h-12 w-auto"
                src="@/assets/logo.png"
                alt="Rayfish"
              />
              <h2
                class="mt-6 text-center text-3xl font-extrabold text-slate-100"
              >
                Create a new account
              </h2>
              <p class="mt-4 text-center text-sm text-slate-300">
                A secure VPN that works out of the box.<br />
                Easily create a network between computers, servers and cloud
                instances.
              </p>
            </div>
            <form class="mt-8 space-y-6" action="#" method="POST">
              <input type="hidden" name="remember" value="true" />
              <div class="rounded-md shadow-sm -space-y-px">
                <div>
                  <label for="email-address" class="sr-only"
                    >Email address</label
                  >
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                    class="appearance-none rounded-none relative block w-full px-4 py-3 border border-zinc-300 placeholder-zinc-500 text-zinc-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-lg"
                    placeholder="Email address"
                    ref="email"
                  />
                </div>
                <div>
                  <label for="password" class="sr-only">Password</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="appearance-none rounded-none relative block w-full px-4 py-3 border border-zinc-300 placeholder-zinc-500 text-zinc-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-lg"
                    placeholder="Password"
                  />
                </div>
                <div>
                  <label for="password_repeat" class="sr-only">Password</label>
                  <input
                    id="password_repeat"
                    name="password_repeat"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="appearance-none rounded-none relative block w-full px-4 py-3 border border-zinc-300 placeholder-zinc-500 text-zinc-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-lg"
                    placeholder="Repeat password"
                  />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input
                    id="accept-terms"
                    name="accept-terms"
                    type="checkbox"
                    class="h-4 w-4 text-zinc-600 focus:ring-indigo-500 border-zinc-300 rounded"
                  />
                  <label
                    for="accept-terms"
                    class="ml-2 block text-sm text-zinc-300"
                  >
                    I accept the
                    <router-link
                      class="hover:underline text-zinc-200"
                      to="/terms-and-conditions"
                      >Terms and Conditions</router-link
                    >
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="group transform duration-500 relative w-full flex justify-center py-2 px-4 border border-transparent font-semibold text-lg rounded-full text-zinc-100 bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Create account
                </button>
              </div>
            </form>

            <p class="text-center text-slate-300">
              Already have an account?
              <router-link
                to="/login"
                class="font-medium text-blue-400 hover:underline"
              >
                Log in
              </router-link>
            </p>
          </div>
        </div>

        <div
          class="col-span-4 w-96 flex items-center justify-center hidden lg:grid"
        >
          <div class="flex flex-col space-y-12">
            <div class="grid grid-cols-12">
              <div class="flex justify-start col-span-2">
                <div class="flex flex-col">
                  <div
                    class="p-3 rounded-full bg-zinc-600 flex items-center justify-center text-zinc-300"
                  >
                    <CubeTransparentIcon class="h-7 w-7" />
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col items-left justify-start col-span-10 text-zinc-200 px-4"
              >
                <span class="font-semibold uppercase">Unlimited Users</span>
                <p class="mt-1 text-zinc-300">
                  Add as much devices as you want. Rayfish is free for
                  non-commercial use.
                </p>
              </div>
            </div>

            <div class="grid grid-cols-12">
              <div class="flex justify-start col-span-2">
                <div class="flex flex-col">
                  <div
                    class="p-3 rounded-full bg-zinc-600 flex items-center justify-center text-zinc-300"
                  >
                    <GlobeAltIcon class="h-7 w-7" />
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col items-left justify-start col-span-10 text-zinc-200 px-4"
              >
                <span class="font-semibold uppercase"
                  >Point-to-point connections</span
                >
                <p class="mt-1 text-zinc-300">
                  Low latency and encrypted. None of your traffic ever touches
                  our servers.
                </p>
              </div>
            </div>

            <div class="grid grid-cols-12">
              <div class="flex justify-start col-span-2">
                <div class="flex flex-col">
                  <div
                    class="p-3 rounded-full bg-zinc-600 flex items-center justify-center text-zinc-200"
                  >
                    <DocumentSearchIcon class="h-7 w-7" />
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col items-left justify-start col-span-10 text-zinc-200 px-4"
              >
                <span class="font-semibold uppercase"
                  >Audit-compliant logging</span
                >
                <p class="mt-1 text-zinc-300">
                  Logging from both ends of the connection ensures your network
                  traffic is tamper-proof.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  CubeTransparentIcon,
  DocumentSearchIcon,
  GlobeAltIcon,
} from "@heroicons/vue/outline";

export default {
  name: "RegisterView",
  components: {
    CubeTransparentIcon,
    DocumentSearchIcon,
    GlobeAltIcon,
  },

  mounted() {
    this.$refs.email.focus();
  },
};
</script>

<style scoped></style>
