<template>
  <div
    class="h-full relative pt-6 font-robot tracking-wider flex flex-col bg-zinc-900"
    ref="app"
  >
    <TopNav />
    <router-view class="grow" />
    <!--<FooterComp />-->
  </div>
</template>

<script>
import { gsap } from "gsap";
import TopNav from "@/components/TopNav";
export default {
  components: { TopNav },

  mounted() {
    gsap.to(this.$refs.app, {
      background: "rgba(24, 24, 27, 0)",
      duration: 2,
      delay: 2,
    });
  },
};
</script>
