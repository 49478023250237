<template>
  <main class="h-full mx-auto max-w-7xl px-4 sm:px-6">
    <div
      class="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full space-y-12">
        <div>
          <img
            class="mx-auto h-12 w-auto"
            src="@/assets/logo.png"
            alt="Rayfish"
          />
          <h2 class="mt-6 text-center text-3xl font-extrabold text-slate-100">
            Forgot your password?
          </h2>
        </div>
        <form class="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">Email address</label>
              <input
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="appearance-none relative block w-full px-4 py-3 border border-zinc-300 placeholder-zinc-500 text-zinc-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-lg"
                placeholder="Email address"
                ref="email"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="group transform duration-500 relative w-full flex justify-center py-2 px-4 border border-transparent font-semibold text-lg rounded-full text-zinc-100 bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Reset password
            </button>
          </div>
        </form>

        <p class="text-center text-slate-200">
          Remember your password?
          <router-link
            to="/login"
            class="font-medium text-blue-400 hover:underline"
          >
            Log in
          </router-link>
        </p>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "LoginView",

  mounted() {
    this.$refs.email.focus();
  },
};
</script>

<style scoped></style>
