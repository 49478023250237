<template>
  <div class="flex flex-col justify-center items-center">
    <main class="mx-auto max-w-7xl px-4">
      <div
        class="lg:grid lg:grid-cols-12 lg:gap-8 lg:pl-4"
        style="height: 450px"
      >
        <div
          class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
        >
          <h1 ref="heading" class="">
            <span
              class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl"
            >
              <span class="block text-zinc-50">{{ s1 }}</span>
              <span class="block text-rose-600">{{ s2 }}</span>
            </span>
          </h1>
          <div ref="subtext" class="mt-12 opacity-0">
            <p
              class="mt-3 text-base font-light text-zinc-100/80 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
            >
              <span class="text-zinc-100 font-bold">Own your network.</span>
              Rayfish creates an independent and secure network between your
              computers, servers and cloud instances.
            </p>
          </div>

          <div ref="form" class="mt-12 opacity-0">
            <div
              class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"
            >
              <p class="text-base font-medium text-zinc-100">
                Sign up to get early access.
              </p>
              <form v-on:submit.prevent="postEmail" class="mt-3 sm:flex">
                <div class="w-full relative">
                  <label for="email" class="sr-only">Email</label>
                  <input
                    type="email"
                    name="email"
                    ref="email"
                    v-model="email"
                    id="email"
                    autocomplete="off"
                    class="bg-zinc-700/30 border-none appearance-none relative block w-full px-6 py-4 placeholder-zinc-100/25 font-robot text-zinc-50 rounded-2xl focus:outline-none focus:ring-blue-500 focus:ring-2 focus:ring-offset-2 transition duration-200 focus:ring-offset-zinc-900 focus:z-10 sm:text-lg"
                    placeholder="Enter your email"
                    :disabled="processing"
                  />

                  <div
                    v-show="processing"
                    class="-mt-[3.75rem] rounded-2xl absolute w-full h-full bg-zinc-900/60 transform duration-500 flex flex-row items-center justify-center"
                  >
                    <div role="status">
                      <svg
                        class="inline mr-2 w-6 h-6 text-gray-200 animate-spin fill-rose-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="transform duration-500 mt-3 w-full px-6 py-3 border border-transparent text-base font-semibold rounded-full text-zinc-900 bg-zinc-200 shadow-sm hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                >
                  Get early access
                </button>
              </form>

              <div
                v-show="success"
                class="text-emerald-500 mt-2 transform duration-500"
              >
                You've been added to the beta list. Please check your email to
                confirm.
              </div>

              <p class="mt-3 text-sm text-zinc-100/30">
                We care about the protection of your data.
              </p>
            </div>
          </div>
        </div>
        <div
          class="hidden lg:block lg:col-span-6 text-right flex flex-row items-center justify-end opacity-0"
          ref="cloud"
        >
          <img
            src="@/assets/network.svg"
            alt="Rayfish network"
            class="-mt-12 block mx-auto"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "HomeView",
  components: {},

  data() {
    return {
      email: "",
      processing: false,
      success: false,

      s1: "",
      s2: "",
    };
  },

  mounted() {
    this.s1 = "_";

    let wait = 300;
    const step = 70;

    setTimeout(
      function (vm) {
        vm.s1 = "Z_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Ze_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zer_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zero_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zero t_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zero tr_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zero tru_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zero trus_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zero trust_";
      },
      wait,
      this
    );
    wait = wait + step;

    wait = wait + step;
    wait = wait + step;
    wait = wait + step;
    wait = wait + step;
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s1 = "Zero trust";
        vm.s2 = "P_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Pr_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Pri_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Priv_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Priva_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Privat_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private n_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private ne_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private net_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private netw_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private netwo_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private networ_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private network_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private networks_";
      },
      wait,
      this
    );
    wait = wait + step;

    setTimeout(
      function (vm) {
        vm.s2 = "Private networks.";
      },
      wait,
      this
    );
    wait = wait + step;

    gsap.to(this.$refs.subtext, {
      opacity: 1,
      marginTop: 0,
      duration: 0.8,
      delay: 3,
    });

    gsap.to(this.$refs.form, {
      opacity: 1,
      marginTop: 0,
      duration: 0.8,
      delay: 3.4,
    });

    gsap.to(this.$refs.cloud, {
      opacity: 1,
      marginTop: 0,
      duration: 0.6,
      delay: 3.8,
    });

    setTimeout(
      function (vm) {
        vm.$refs.email.focus();
      },
      3800,
      this
    );
  },

  methods: {
    postEmail() {
      this.success = false;

      if (this.processing) {
        return;
      }

      if (
        !String(this.email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        return;
      }

      this.processing = true;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.email }),
      };
      fetch("/subscribers", requestOptions)
        .then((response) => response.json())
        .then(() => {
          this.success = true;
          this.resetForm();
          this.processing = false;
        });
    },

    resetForm() {
      this.email = "";
    },
  },
};
</script>
